@use '@fixed-size' as *;
@use '@content' as *;
@use '@typography' as *;
@use '@queries' as *;

.additional-info,
.expandable {
  display: flex;
  align-items: center;

  padding: 10px;
  border-radius: 10px;

  justify-content: space-between;
  gap: 12px;

  @include min-428-break {
    gap: 14px;
    padding: 12px;
  }

  &--timer,
  &--card {
    box-shadow: none !important;
  }
}

.width {
  &-content {
    width: max-content;
    min-width: 196px;
  }

  &-full-w {
    width: 100%;
    max-width: 100%;
  }
}

.variant {
  &-success {
    p {
      color: var(--cst-additional-info-title-green);
    }
    background: var(--cst-additional-info-background-green);
    box-shadow: inset 0px 0px 0px 2px var(--cst-additional-info-outline-green);
  }

  &-warn {
    p {
      color: var(--cst-additional-info-title-orange);
    }

    background: var(--cst-additional-info-background-orange);
    box-shadow: inset 0px 0px 0px 2px var(--cst-additional-info-outline-orange);
  }

  &-info {
    p {
      color: var(--cst-additional-info-title-blue);
    }

    background: var(--cst-additional-info-background-blue);
    box-shadow: inset 0px 0px 0px 2px var(--cst-additional-info-outline-blue);
  }

  &-danger {
    p {
      color: var(--cst-additional-info-title-red);
    }

    background: var(--cst-additional-info-background-red);
    box-shadow: inset 0px 0px 0px 2px var(--cst-additional-info-outline-red);
  }
}

.additional-info-icon {
  @include singleFixedSize(16px);

  @include min-428-break {
    @include singleFixedSize(20px);
  }

  &-timer,
  &-card {
    @include singleFixedSize(18px);
  }

  &--success {
    color: var(--global-green600);
  }

  &--info {
    color: var(--global-blue600);
  }

  &--warn {
    color: var(--global-orange600);
  }

  &--danger {
    color: var(--global-red600);
  }
}

.with-background {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  @include singleFixedSize(38px);
  border-radius: var(--cornerradius10);

  &.no-background {
    padding: 0;
    @include singleFixedSize(initial);
    @include min-428-break {
      padding: 0;
      @include singleFixedSize(initial);
    }
  }

  span[id='additional-info-icon'] {
    @include singleFixedSize(18px);
  }

  @include min-428-break {
    padding: 12px;
    @include singleFixedSize(42px);
  }

  &--success {
    background: rgba(64, 187, 24, 0.1);
  }

  &--warn {
    background: rgba(254, 153, 32, 0.1);
  }

  &--danger {
    background: rgba(235, 60, 60, 0.1);
  }
}

.info {
  @include typography-s;
  @include weight-semi-bold;

  @include min-428-break {
    @include typography-base;
  }

  &-timer,
  &-card {
    @include typography-xs;
    @include weight-semi-bold;

    @include min-428-break {
      @include typography-s;
    }
  }

  &-timer {
    white-space: nowrap;
  }
}

.expandable {
  .icon {
    align-self: flex-start;
  }

  .cheveron {
    @include singleFixedSize(16px);
    transition: all 0.2s;
    align-self: flex-start;

    &-up {
      transform: rotate(180deg);
    }

    &-down {
      transform: none;
    }

    @include min-428-break {
      @include singleFixedSize(20px);
    }
  }

  .info-container {
    overflow-y: auto;
    @include hideScrollbar;

    .info {
      @include typography-xs;
      @include weight-medium;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      &-on {
        text-overflow: initial;
        white-space: initial;
        overflow: initial;

        max-width: inherit;
      }

      @include min-428-break {
        @include typography-s;
      }
    }
  }

  &.width {
    &-full-w,
    &-content {
    }
  }
}

.timer-icon,
.card-icon {
  display: flex;
  align-items: center;
  gap: 8px;

  @include min-428-break {
    gap: 10px;
  }
}
